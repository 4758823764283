<template>
    <v-card v-show="canSendMessages" flat class="message-form py-3" :class="messageCategory.color">
        <v-form @submit="onSubmit" @reset="onReset">
            <v-card flat :color="messageCategory.color">
                <v-textarea
                        outlined
                        rounded
                        background-color="white"
                        clearable
                        :id="'message-form-text-area-' + messageCategory.id"
                        auto-grow
                        v-model="form.content"
                        ref="content"
                        :rows="inputRows"
                        hide-details
                        :disabled="submitting"
                        :placeholder="placeholder"
                        v-on:keyup.enter="onEnter"
                >
                    <template v-slot:append>
                        <v-icon v-if="showAdvancedMessageIcon" @click="openMessageModal" left dense>ter-three-bars
                        </v-icon>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <file-upload
                                        v-on="on"
                                        class=""
                                        :post-action="upload.postAction"
                                        :extensions="upload.extensions"
                                        :accept="upload.accept"
                                        :multiple="upload.multiple"
                                        :headers="upload.headers"
                                        :size="upload.size"
                                        :drop="upload.drop"
                                        v-model="files"
                                        @input-filter="inputFilter"
                                        @input-file="inputFile"
                                        ref="upload"
                                >
                                    <v-icon>$vuetify.icons.attachment</v-icon>
                                </file-upload>
                            </template>
                            Odesílání jpg obrázku
                        </v-tooltip>
                    </template>

                    <template v-slot:append-outer>
                        <v-btn fab type="submit" small dark color="success">
                            <v-icon>send</v-icon>
                        </v-btn>
                    </template>
                </v-textarea>
            </v-card>
            <v-simple-table v-if="messageFiles.length > 0">
                <template v-slot:default>
                    <tbody>
                    <tr v-for="(messageFile, key) in messageFiles" :key="key">
                        <td>
                            <v-img width="60" v-if="messageFile.file.thumb" :src="messageFile.file.thumb"/>
                        </td>
                        <td>příloha: {{ messageFile.name }}</td>
                        <td>{{ messageFile.status }}</td>
                        <td>
                            <v-btn icon small @click.prevent="removeAttachment(messageFile.file)">
                                <v-icon>delete</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-form>
    </v-card>
</template>

<script>
import {mapState} from 'vuex'
import {validationMixin} from 'vuelidate'
import {requiredIf} from 'vuelidate/lib/validators'
import moment from "moment/moment";
import forEach from "lodash/forEach";
import isEmpty from "lodash/isEmpty";
import FileUpload from 'vue-upload-component'
import app from "@/api/app";

import {faFileUpload as upload,} from '@fortawesome/free-solid-svg-icons'
import {appBus} from "@/main";
import {ON_ADVANCED_MESSAGE} from "@/events";

export default {
    name: 'sidebar-event-message',
    components: {
        FileUpload,
    },
    created() {
        appBus.$on('onAddToMessageContent', (content) => {
            this.addToMessageContent(content);
        });
    },
    mounted() {
        if (!this.isMobile) {
            const input = document.getElementById('message-form-text-area-' + this.messageCategory.id)
            if (input) {
                setTimeout(() => {
                    input.focus()
                }, 50)
            }
        }
        // if (this.canSendMessages) {
        this.$watch(
            () => {
                return this.$refs.upload.uploaded
            },
            (val) => {
                if (this.isFiles() && val === true) {
                    let error = false;
                    forEach(this.files, function (file) {
                        if (file.error)
                            error = true;
                    });
                    if (!error) {
                        this.send();
                    } else {
                        this.$store.dispatch("notifications/add", {
                            type: 'error',
                            message: 'Zpráva nebyla odeslána, nastala chyba při nahrávání přílohy.'
                        });
                        this.files = [];
                        this.submitting = false;
                    }
                }
            }
        )
        // }
    },
    props: {
        eventId: Number,
        multiple: {
            type: Boolean,
            default: false,
        },
        advancedMessage: {
            type: Boolean,
            default: false,
        },
        messageCategory: {
            type: Object,
            required: true,
        },
        inputRows: {
            type: Number,
            default: 1,
        },
        messageType: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            submitting: false,
            icons: {
                upload,
            },
            form: {
                content: '',
                latitude: null,
                longitude: null,
                media: {},
                data: {},
            },
            files: [],
            messageFilesHeaders: [
                {
                    text: "Name",
                    align: "left",
                    sortable: "false",
                    value: "name",
                },
                {
                    text: "Size",
                    align: "center",
                    sortable: "false",
                    value: "size",
                },
                {
                    text: "Status",
                    align: "right",
                    sortable: "false",
                    value: "status",
                },
            ],
        }
    },
    computed: {
        ...mapState({
            device: state => state.auth.device,
            apiUrl: state => state.tereza.apiUrl,
            sendOnEnter: state => state.setup.events.messages.form.sendOnEnter,
            isMobile: state => state.isMobile,
        }),
        showAdvancedMessageIcon() {
            return this.advancedMessage && isEmpty(this.form.content);
        },
        canSendMessages() {
            return this.$can('manage', 'Message' + this.messageCategory.id)
        },
        uploadLimit() {
            return this.$can('view', 'Debug') ? 10 : 5;
        },
        placeholder() {
            return this.messageCategory?.options?.placeholder;
        },
        upload() {
            return {
                accept: 'image/jpeg,application/pdf',
                extensions: ['jpg', 'pdf', 'mp3'],
                size: 1024 * 1024 * this.uploadLimit,
                multiple: this.multiple,
                drop: true,
                headers: {
                    'Authorization': this.$store.getters['auth/token'],
                },
            }
        },
        messageFiles() {
            let files = [];
            forEach(this.files, function (file) {

                let status;
                if (file.error) {
                    status = 'chyba';
                } else if (file.success) {
                    status = 'nahráno';
                } else if (file.active) {
                    status = 'nahrávám...';
                } else {
                    status = '';
                }

                files.push({
                    name: file.name,
                    size: file.size,
                    file: file,
                    status: status,
                });
            });
            return files;
        }
    },
    mixins: [validationMixin],
    validations: {
        form: {
            content: {
                required: requiredIf(function () {
                    return isEmpty(this.files)
                })
            },
        }
    },
    methods: {
        setContentFromMessage(message) {
            this.form.content = message.content;
            this.form.latitude = message.latitude;
            this.form.longitude = message.longitude;

            if (message.media) {
                forEach(message.media, (media) => {
                    app.getMedia(message.eventId, media).then((response) => {
                        let file = new File([response.data], media, {type: '', lastModified: Date.now()});
                        this.$refs.upload.add(file)
                    }).catch(function (error) {
                        window.console.error('error', error);
                    });
                });

            }
        },
        openMessageModal() {
            appBus.$emit(ON_ADVANCED_MESSAGE, this.messageCategory);
        },
        onSubmit(evt = null) {
            if (evt)
                evt.preventDefault();

            this.submitting = true;
            this.$v.$touch();

            if (this.$v.$invalid) {
                this.$noty.error("Zadejte text zprávy");
                this.submitting = false;
            } else {
                if (this.isFiles()) {
                    this.$refs.upload.active = true;
                } else {
                    this.send();
                }
            }
        },
        removeAttachment(file) {
            this.$refs.upload.remove(file);
        },
        onEnter(evt) {
            if (evt.ctrlKey) {
                this.form.content = [this.form.content.slice(0, evt.target.selectionEnd), '\n', this.form.content.slice(evt.target.selectionEnd)].join('');
                this.$nextTick(this.$refs.content.calculateInputHeight);
                return;
            }

            if (this.sendOnEnter)
                this.onSubmit(evt);
        },
        send() {

            let files = this.getFiles();
            let type = this.messageType;
            if (!type) {
                type = files ? 'X22' : 'X03';
            }

            let message = {
                content: this.form.content,
                latitude: this.form.latitude,
                longitude: this.form.longitude,
                messageCategoryId: this.messageCategory.id,
                type: type,
                media: this.getFiles(),
            };
            this.$store.dispatch('messages/send', message).then(() => {
                this.resetForm();
                this.submitting = false;
                this.$emit('onMessageSend');
            });
        },
        addToMessageContent(content) {
            this.form.content = this.form.content + content;
        },
        onReset(evt) {
            evt.preventDefault();
            this.reset();
        },
        resetForm() {
            this.$refs.upload.clear();
            this.form.content = '';
        },
        replaceContent(content) {
            this.form.content = content;
        },
        isFiles() {
            return !isEmpty(this.files);
        },
        getFiles() {
            if (this.isFiles()) {

                let files = [];
                forEach(this.files, function (file) {
                    files.push(file.filename);
                });
                return files;
            } else {
                return null
            }
        },
        inputFilter(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {

                if (newFile.size > this.upload.size) {
                    this.$store.dispatch("notifications/add", {
                        type: 'error',
                        message: 'Příloha překročila povolenou velikost ' + this.uploadLimit + 'MB.'
                    });
                    return prevent();
                }

                let extension = newFile.name.split('.').pop().toLowerCase();
                newFile.filename = this.eventId + '-' + moment().format('YYYYMMDD_HHmmss') + '-' + this.device.code + '.' + extension;
                newFile.headers['X-File-Size-Total'] = newFile.size;
                newFile.postAction = this.apiUrl + '/v2/media/event/' + this.eventId + '/' + newFile.filename;
            }
        },
        inputFile(newFile, oldFile) {
            if (newFile && oldFile) {
                if (newFile.active && !oldFile.active) {
                    if (newFile.size >= this.upload.size) {
                        this.$refs.upload.update(newFile, {error: 'size'})
                    }
                }
            }

            if (newFile && !newFile.thumb && (newFile.type.startsWith('image/') || /\.(jpe?g|png|gif|bmp|webp)$/i.test(newFile.name))) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    newFile.thumb = e.target.result;
                    this.files = [...this.files];
                };
                reader.readAsDataURL(newFile.file);
            }
        },
    },
    beforeDestroy() {
        appBus.$off('onAddToMessageContent');
    }
}
</script>

<style lang="scss">
.message-form {

  .table tr:first-child td {
    border: none;
  }

  padding: 5px 15px;

  .file-uploads {
    display: inline-flex;

    label {
      cursor: pointer;
    }
  }

}
</style>
