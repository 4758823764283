<template>
    <v-dialog v-model="modal" transition="slide-y-transition" :scrollable="false" max-width="1200">
        <v-card style="height: 90vh" class="d-flex flex-column" :loading="processing" :disabled="processing">
            <v-toolbar flat dense dark height="48">
                <div>Tvorba zprávy do kanálu: {{ messageCategory.name }}</div>
                <v-spacer></v-spacer>
                <v-btn rounded small light class="mt-1" @click="modal=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-container fluid class="overflow-hidden flex-grow-1 pa-0">
                <v-row no-gutters>
                    <v-col cols="6" class="fill-height">
                        <v-card flat class="d-flex flex-column overflow-y-auto" style="height: calc(90vh - 48px)">
                            <v-card-text>
                                Šablony
                            </v-card-text>

                            <v-list three-line>
                                <v-divider></v-divider>
                                <template v-for="(item, key) in messageTemplates">
                                    <v-list-item :key="item.id" @click="insertMessageTemplate(item)">
                                        <v-list-item-content>
                                            <v-list-item-title v-html="item.name"
                                                               class="font-weight-bold"></v-list-item-title>
                                            <v-list-item-subtitle v-html="item.content"></v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn icon x-large @click="insertMessageTemplate(item)">
                                                <v-icon x-large>ter-right-open</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-divider :key="key"></v-divider>
                                </template>
                            </v-list>
                        </v-card>
                    </v-col>
                    <v-col cols="6" class="grey lighten-2">
                        <message-form ref="messageForm" :eventId="event.id"
                                      :messageCategory="messageCategory" :multiple="true"
                                      :input-rows="4" :message-type="messageType" @onMessageSend="onMessageSend">
                        </message-form>
                        <div v-if="currentMessageTemplate" class="px-4 text-caption text-right">
                            Current Message template: {{ currentMessageTemplate.type }}
                            <message-tags-context-menu :message="message" :tags="messageTags">
                            </message-tags-context-menu>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
import {appBus} from "@/main";
import MessageForm from './MessageForm'
import {ON_ADVANCED_MESSAGE} from "@/events";
import api from "@/api/api";
import MessageTagsContextMenu from "@/components/panel/sidebar/event/MessageTags.vue";

export default {
    name: 'modal-event-advanced-message',
    components: {
        MessageTagsContextMenu,
        MessageForm
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
    },
    created() {
        // this.modal = true;
        appBus.$on(ON_ADVANCED_MESSAGE, (messageCategory) => {
            this.messageCategory = messageCategory;
            this.showModal();
        });
    },
    data() {
        return {
            modal: false,
            processing: false,
            messageTemplates: [],
            currentMessageTemplate: null,
            messageCategory: {
                id: 2,
                name: 'test',
            },
        }
    },
    computed: {
        messageType() {
            return this.currentMessageTemplate?.type;
        },
        messageTags() {
            return this.$store.getters['messages/availableTags'](this.message, []);
        },
        message() {
            return {
                eventId: this.event.id,
                type: this.messageType,
                messageCategoryId: this.messageCategory?.id,
                deviceId: null,
            }
        }
    },
    methods: {
        onMessageSend() {
            this.modal = false;
        },
        insertMessageTemplate(item) {
            this.processing = true;
            api.get('/v2/message-templates/' + item.id + '/event/' + this.event.id)
                .then((response) => {
                    this.currentMessageTemplate = response;
                    this.$refs.messageForm.replaceContent(this.currentMessageTemplate.content);
                })
                .catch(() => {

                })
                .finally(() => {
                    this.processing = false;
                });
        },
        showModal() {
            this.modal = true;
            this.$nextTick(() => {
                this.$refs.messageForm.resetForm();
            });
        },
        loadTemplates() {
            this.processing = true;
            api.get('/v2/message-templates/event/' + this.event.id + '/' + this.messageCategory.id)
                .then((response) => {
                    this.messageTemplates = response;
                })
                .catch(() => {

                })
                .finally(() => {
                    this.processing = false;
                });
        }
    },
    watch: {
        modal(value) {
            if (value === false) {
                this.messageCategory = {};
            } else {
                this.loadTemplates();
            }
        }
    },
    beforeDestroy() {
        appBus.$off(ON_ADVANCED_MESSAGE);
    }
}
</script>
